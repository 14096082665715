import React, { useRef, useEffect, useState } from "react";
import AboutUs from "./Components/AboutUs";
import ContactUs from "./Components/ContactUs";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import HomeProducts from "./Components/HomeProducts";
import Ourstory from "./Components/Ourstory";
import { useLocation } from "react-router-dom";

export default function App() {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    window.scroll({
      top: position.top,
      left: position.left,
      behavior: "smooth",
    });
  });
  const [visibility, setVisibility] = useState(false);
  const scrollTop = useRef();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollTop.current) {
        scrollTop.current.style.display =
          window.scrollY > 200 ? "inline-block" : "none";
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const homeRef = useRef();
  const ProductRef = useRef();
  const AboutRef = useRef();
  const contactUsRef = useRef();

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      // Helper function to handle scrolling with an offset
      const scrollToWithOffset = (ref, offset = 200) => {
        if (ref.current) {
          const elementPosition =
            ref.current.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      };

      // Determine which section to scroll to based on state
      if (location.state.scrollTo === "contactus") {
        scrollToWithOffset(contactUsRef);
      }
      if (location.state.scrollTo === "products") {
        scrollToWithOffset(ProductRef);
      }
      if (location.state.scrollTo === "home") {
        scrollToWithOffset(homeRef);
      }
      // Add more conditions for other sections if needed
    }
  }, [location.state]);

  return (
    <>
      <span
        onClick={() =>
          setPosition({ ...position, position: { top: 0, left: 0 } })
        }
        className="circle z-50"
        ref={scrollTop}
      >
        <i class="bx bx-up-arrow-alt"></i>
      </span>
      <Navbar
        homeRef={homeRef}
        contactUsRef={contactUsRef}
        AboutRef={AboutRef}
        ProductRef={ProductRef}
      />

      <Home homeRef={homeRef} />
      <AboutUs AboutRef={AboutRef} />
      <HomeProducts ProductRef={ProductRef} />
      <Ourstory />
      {/* <Testmonial />
      <Services /> */}
      <ContactUs contactUsRef={contactUsRef} />
      <Footer />
    </>
  );
}
