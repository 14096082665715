import React from "react";
import { motion } from "framer-motion";
import leaf from ".././assets/bigLeafDark.svg";
import greenLeaf from ".././assets/leaf-green.svg";
import vision1 from ".././assets/vision1.png";
import { useTranslation } from "react-i18next";
import bg from ".././assets/bg/ourstorybg.svg";

const Ourstory = () => {
  const { t } = useTranslation("home");

  return (
    <>
      {/* Background Section with Title */}
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        className="relative"
      >
        <motion.img
          src={bg}
          alt=""
          className="w-[100%] "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.span
          className="absolute text-white   lg:text-[140px] lg:top-[25%] right-[15%] md:right-[25%] text-[100px] md:top-[12%] md:text-[120px] sm:text-[100px] font-thin top-[15%] text-[50px]"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 2 }}
        >
          {t("ourStory")}
        </motion.span>
      </motion.div>

      {/* Story Section */}
      <div className="w-[80%] m-auto mt-10 overflow-hidden self-center">
        <div className="flex items-center justify-center flex-wrap">
          {/* Left Section */}
          <motion.div
            className=" md:w-[40%] w-[100%] flex flex-col "
            initial={{ x: "-10%", opacity: 0 }}
            whileInView={{ x: "0%", opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="text-[#255718] p-5  items-end text-right text-3xl">
              <span className="md:text-5xl font-semibold italic">
                {t("ourStoryh1")}
              </span>
              <br />
              <p className="mt-4">
                {" "}
                {t("ourStoryh2")}
                <br />
                {t("ourStoryh3")}
              </p>
            </div>
            <img
              src={leaf}
              alt=""
              className="lg:self-end md:self-end self-center pr-10 pb-10 h-[170px] "
            />
          </motion.div>

          {/* Right Section */}
          <motion.div
            className="w-[100%] md:w-[60%]  p-5 leading-7 mt-4 text-xl text-[#28601A]"
            initial={{ x: "-100%", opacity: 0 }}
            whileInView={{ x: "0%", opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {t("ourStoryb1")}
          </motion.div>
        </div>

        {/* Vision Section */}
        <div className="flex items-center mt-20 justify-evenly gap-10 flex-wrap md:ml-4  ">
          {/* Left Vision Image */}
          <motion.div
            className=" md:w-[45%] lg:w-[50%]  w-[100%] flex justify-end 	"
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731521113/Frame_132_gj9qul.png"
              alt=""
              className="w-[250px] md:w-[400px] h-auto "
            />
          </motion.div>

          {/* Right Vision Text */}
          <motion.div
            className="md:w-[45%] lg:w-[40%] w-[100%] "
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={greenLeaf} alt="" className="mb-4" />
            <div className="leading-6 text-lg text-[#28601A] 	">
              {t("ourStoryb2")}
            </div>
          </motion.div>
        </div>

        {/* Sustainability Section */}
        <div className="flex items-center  mt-2 gap-4 flex-wrap relative ">
          {/* Left Text */}
          <motion.div
            className=" md:l-[45%]  md:w-[45%]  w-[100%] lg:order-1 md:order:1 order-2"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={greenLeaf} alt="" className="mb-2" />
            <div className="leading-6 text-left text-lg text-[#28601A] 	">
              {t("ourStoryb3")}
            </div>
          </motion.div>

          {/* Right Image */}
          <motion.div
            className=" md:l-[75%] md:w-[45%] w-[100%] mb-10 lg:order-1 md:order:1 order:1	"
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731521114/Frame_133_ejxbyl.png"
              alt="Vision"
              className="w-[250px] md:w-[400px] h-auto "
            />
          </motion.div>
        </div>
      </div>
      <div className="mt-20">
        <img
          src=" https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731521058/Packaging_photo_final_wboyfz.png"
          alt=""
        />
      </div>
    </>
  );
};

export default Ourstory;
