import React from "react";
import { useTranslation } from "react-i18next";

const ProductCardHusk = () => {
  const { t } = useTranslation("products");

  return (
    <div className="bg-[#F4F3F1]  flex flex-col gap-4 p-4  rounded-md w-[100%]">
      <div className="bg-[#DEFFD6] rounded-sm">
        <p className="text-[#28601A] text-3xl font-bold text-center p-2">
          {t("chips")}
        </p>
      </div>
      <div className="flex gap-10 mt-4 lg:flex-nowrap md:flex-nowrap flex-wrap lg:justify-normal justify-center">
        <div>
          <img
            className="rounded-md max-h-[254px] max-w-[256px]"
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731410293/Frame_116_3_chrvgv.svg"
            alt=""
          />
          <div className="bg-[#28601A] p-2 border-r-2 text-white mt-4 text-center rounded-md text-lg font-semibold">
            {t("huskChips")}
          </div>
        </div>
        <div className="italic font-semibold text-lg w-[80%]">
          <p>{t("chipsp1")}</p>
          <p className="font-bold"> {t("chipsp2")}.</p>
          <p className="mt-6">{t("chipsp3")}</p>
          <p className="mt-6">
            {t("chipsp4")}
            <br />{" "}
          </p>
          <p className="mt-6">{t("chipsp5")}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCardHusk;
