import React from "react";
import { useTranslation } from "react-i18next";

const ProductCardCoins = () => {
  const { t } = useTranslation("products");
  const data = ["30 mm", "35 mm", "50 mm", "70 mm", "100 mm"];
  return (
    <div className="bg-[#F4F3F1]  flex flex-col gap-4 p-4  rounded-md w-[100%]">
      <div className="bg-[#DEFFD6] rounded-sm">
        <p className="text-[#28601A] text-3xl font-bold text-center p-2">
          {t("gardenCoins")}
        </p>
      </div>
      <div className="flex gap-10 lg:flex-nowrap md:flex-nowrap flex-wrap lg:justify-normal  justify-center">
        <div>
          <img
            className="rounded-md max-h-[254px] max-w-[256px]"
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730752964/image_23_ypesqg.png"
            alt=""
          />
          <div className="bg-[#28601A] p-2 border-r-2 text-white mt-4 text-center rounded-md text-lg font-semibold">
            {t("pellets")}
          </div>
        </div>
        <div className="w-[80%]">
          <p className="text-[#54A341] font-semibold text-xl mb-4">
            {t("available")}
          </p>
          <div className="flex gap-4  flex-wrap  lg:justify-normal  justify-center">
            <div className="flex gap-4 flex-wrap">
              {data.map((d) => {
                return (
                  <p className="border-[#54A341] border-2 bg-white p-2 rounded-md font-bold">
                    {d}
                  </p>
                );
              })}
            </div>
            <div>
              <p className="font-bold text-xl mt-2">{t("ordering")} </p>
              <p className="font-semibold text-lg lg:w-[70%] md:w-[80%] mt-4 italic">
                {t("coinsbody")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardCoins;
