import React from "react";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo-box.svg";
import logo1 from "../assets/logo-brown.svg";
import img1 from "../assets/heart-green-nature-isolated-white-background 7.svg";

const KnowMore = () => {
  const { t } = useTranslation("knowmore");
  const navigate = useNavigate();
  const data = [
    {
      header: t("knowch1"),
      main: t("knowcp1"),
    },
    {
      header: t("knowch2"),
      main: t("knowcp2"),
    },
    {
      header: t("knowch3"),
      main: t("knowcp3"),
    },
    {
      header: t("knowch4"),
      main: t("knowcp4"),
    },
  ];

  const data1 = [
    {
      header: t("knowc1h1"),
      main: t("knowc1p1"),
    },
    {
      header: t("knowc1h2"),
      main: t("knowc1p2"),
    },
    {
      header: t("knowc1h3"),
      main: t("knowc1p3"),
    },
  ];

  return (
    <div className="overflow-hidden">
      <Navbar />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="relative overflow-hidden max-h-[700px]"
      >
        <img
          src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730093544/20f3eb9c99be8e30ab20a100b58d1734_jcjgkq.jpg"
          alt="Background"
          width="100%"
          className="filter brightness-50"
        />
        <motion.p
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
          className="absolute inset-0 flex items-center p-10 justify-start 
            lg:text-[60px] md:text-[60px] sm:text-[60px]
          lg:top-[5%] 
          md:top-[5%]
          sm:top-[-15%]
          top-[5%]
        lg:left-20
        md:left-20
          text-[30px] 
            text-white font-sans font-extralight"
        >
          <span className="font-extralight font-sans mr-4 text-[#bababa]  ">
            {" "}
            {t("at")}{" "}
          </span>
          {t("virglow")}
        </motion.p>
      </motion.div>
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        whileHover={{ scale: 1.1 }}
        onClick={() => navigate("/")}
        className="flex justify-center gap-2 mt-10 items-center flex-wrap cursor-pointer"
      >
        <i className="fa-solid fa-chevron-left" />
        <span>{t("home")} / </span>
        <span className="text-3xl text-[#54A341] font-semibold">
          {t("knowMore")}
        </span>
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="flex flex-col relative items-center justify-evenly   bg-[#DEFFD6] lg:h-[300px] md:h-[300px] h-[600px] lg:m-36 md:m-20 m-10 rounded-md "
      >
        <motion.p className="lg:text-3xl md:text-3xl text-xl text-[#54A341] font-semibold text-center ">
          {t("knowh1")}
        </motion.p>
        <motion.p className="lg:w-[60%] md:w-[60%] w-[80%] text-center">
          {t("knowp1")}
        </motion.p>
        <motion.img className="absolute left-0 bottom-0" src={logo} alt="" />
      </motion.div>
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        className="flex flex-col justify-center items-center mt-20 gap-4 p-4"
      >
        <p className="lg:text-4xl md:text-4xl text-2xl text-[#54A341] font-semibold ">
          {t("knowh2")}
        </p>
        <p className="text-lg">{t("knowp2")}</p>
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="mt-20 flex flex-col"
      >
        <motion.p className="text-[#28601A] text-3xl font-medium lg:w-[70%] md:w-[70%]  self-center">
          {t("knowh3")}
        </motion.p>
        <motion.div className="flex flex-wrap items-center">
          <motion.div className="lg:w-[50%] md:w-[50%] w-[95%] lg:h-[400px] md:h-[400px] h-[300px] ">
            <img src={img1} alt="" />
          </motion.div>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="lg:w-[50%] md:w-[50%]  lg:mt-10 md:mt-10 p-4 flex items-center"
          >
            <motion.div className="flex flex-col gap-6 mt-10">
              {data.map((d, index) => (
                <motion.div key={index}>
                  <p className="text-2xl text-[#54A341] font-medium ">
                    {d.header}
                  </p>
                  <p className="italic mt-2">{d.main}</p>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      <motion.div className="mt-10 flex justify-evenly p-10 flex-wrap">
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="lg:w-[50%] md:w-[50%] w-[90%] mt-10 lg:order-1 md:order-1 order-2"
        >
          <motion.div className="flex flex-col gap-6 mt-24">
            {data1.map((d, index) => (
              <motion.div key={index}>
                <p className="text-2xl  text-[#54A341] font-medium">
                  {d.header}
                </p>
                <p className="italic mt-2">{d.main}</p>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="flex flex-wrap lg:w-[35%] md:w-[35%] w-[90%] lg:mt-10 md:mt-10 order-1"
        >
          <motion.p className="text-[#28601A] text-3xl font-medium w-[100%] text-center">
            {t("knowh4")}
          </motion.p>
          <motion.div className="lg:h-[400px] md:h-[400px] h-[300px]">
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731389484/e9e842af030d7821e06f9c0b792df777_1_vihvva.svg"
              alt=""
            />
          </motion.div>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="flex flex-col relative items-center justify-evenly  bg-[#FFF2D0] lg:h-[250px] md:h-[250px] h-[450px] lg:m-36 md:m-20 m-10 rounded-md"
      >
        <motion.p className="lg:text-3xl md:text-3xl text-xl text-[#7A3D06] font-semibold  ">
          {t("knowh5")}
        </motion.p>
        <motion.p className="lg:w-[60%] md:w-[60%] w-[90%] text-center">
          {t("knowp5")}
        </motion.p>
        <motion.img className="absolute left-0 bottom-0" src={logo1} alt="" />
      </motion.div>
      <Footer />
    </div>
  );
};

export default KnowMore;
