import React from "react";

const Loader = () => {
  return (
    <img
      src="https://cdn.dribbble.com/users/2077073/screenshots/6005120/loadin_gif.gif"
      alt=""
    />
  );
};

export default Loader;
