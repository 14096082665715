import React from "react";
import * as motion from "framer-motion/client";
import leaf from "../assets/leaf-white.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = ({ homeRef }) => {
  const navigate = useNavigate();

  const { t } = useTranslation("home");

  const handleNavigation = () => {
    // Push a new path to the history stack
    navigate("/know-more");
  };

  return (
    <div
      ref={homeRef}
      className="bg-[#28601A] flex items-center text-white   font-sans overflow-hidden lg:h-[750px]
      md:h-[700px]
      "
    >
      <div className="flex w-full flex-wrap">
        <div className="lg:w-1/2 md:w-1/2  sm:w-1/2 w-full  flex items-center justify-end  ">
          <div className="flex flex-col  mr-10 p-8">
            <motion.div
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 1.1 }}
              drag="x"
              initial={{ x: "80%", opacity: 0 }}
              animate={{ x: "10%" }}
              transition={{ duration: 2 }}
              whileInView={{ opacity: 1 }}
              dragConstraints={{ left: -50, right: 50 }}
              className="text-right mt-10"
            >
              <p className="font-medium lg:text-6xl md:text-6xl  text-5xl">
                {t("transforming")}
              </p>
              <p className="font-medium lg:text-6xl md:text-6xl  text-5xl">
                {t("agriculture")}
              </p>
            </motion.div>
            <motion.p
              initial={{ x: "-80%", opacity: 0 }}
              animate={{ x: "0%" }}
              transition={{ duration: 1 }}
              whileInView={{ opacity: 1 }}
              className="  text-right text-4xl font-thin mt-[180px]  leading-1 font-sans "
            >
              {t("body1")}
              <br /> {t("body2")}
            </motion.p>

            <motion.div
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 1.05 }}
              transition={{ duration: 0.5 }}
              className="self-end mt-10 "
            >
              <button className="bg-[#603126] p-2" onClick={handleNavigation}>
                <div className="flex gap-1">
                  <img src={leaf} />
                  {t("knowMore")}
                </div>
              </button>
            </motion.div>
          </div>
        </div>
        <div
          className="lg:w-1/2 md:w-1/2  sm:w-1/2 lg:h-[750px] md:h-[750px] h-[600px] sm:mt-0 md:mt-0 w-full mt-10 lg:mt-0
        bgimage
        "
        ></div>
      </div>
    </div>
  );
};

export default Home;
