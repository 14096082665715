import React from "react";
import { useTranslation } from "react-i18next";

const ProductCardDisc = () => {
  const { t } = useTranslation("products");

  return (
    <div className="bg-[#F4F3F1]  flex flex-col gap-4 p-4  rounded-md w-[100%]">
      <div className="bg-[#DEFFD6] rounded-sm">
        <p className="text-[#28601A] text-3xl font-bold text-center p-2">
          {t("cocoPeatDiscs")}
        </p>
      </div>
      <div className="flex gap-10 lg:flex-nowrap lg:justify-normal justify-center flex-wrap">
        <div>
          <img
            className="rounded-md max-h-[254px] max-w-[256px]"
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731437690/Frame_54_2_vhw6ss.png"
            alt=""
          />
          <div className="bg-[#28601A] p-2 border-r-2 text-white mt-4 text-center rounded-md text-lg font-semibold">
            {t("disc")}
          </div>
        </div>
        <div>
          <p className="text-[#54A341] text-xl mb-4 font-semibold">
            {t("available")}
          </p>
          <div className="flex gap-4 flex-wrap  lg:justify-normal justify-center ">
            <div className="rounded-lg  border-2 border-gray-200 w-[200px]">
              <div id="p1" className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[70px] "
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730749327/image_15_bpsfrn.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-2xl text-white p-2  text-center flex  items-center justify-center font-semibold h-[80px] gap-2">
                <div>
                  <p>100g</p>
                  <p>(10 cm) </p>
                </div>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">170</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] rounded-lg h-[40px] bg-[#D0D0D0]">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">40</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pallets/Container")} </p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] rounded-lg bg-[#D0D0D0]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">136000</p>
                </div>
              </div>
            </div>
            <div
              id="p2"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[70px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730749327/image_15_bpsfrn.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-2xl text-center flex  items-center justify-center font-semibold h-[80px] gap-2">
                <div>
                  <p>350g</p>
                  <p>(15 cm)</p>
                </div>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">64</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">42</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pallets/Container")} </p>
                  <p className="text-black font-bold">21</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">56448</p>
                </div>
              </div>
            </div>
            <div className="rounded-lg  border-2 border-gray-200 w-[200px]">
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[70px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730749327/image_15_bpsfrn.png"
                  alt=""
                />
              </div>
              <div
                id="p3"
                className="bg-[#54A341] text-white p-2 text-2xl text-center flex  items-center justify-center font-semibold h-[80px] gap-2"
              >
                <div>
                  <p>600g</p>
                  <p>(17.5 cm)</p>
                </div>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">30</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">42</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pallets/Container")} </p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">25200</p>
                </div>
              </div>
            </div>
            <div
              id="p4"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[70px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730749327/image_15_bpsfrn.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-2xl text-white p-2  text-center flex  items-center justify-center font-semibold h-[80px] gap-2">
                <div>
                  <p>1.2kg</p>
                  <p>(24 cm)</p>
                </div>
              </div>
              <div className="flex flex-col items-center text-[#717171] font-semibold">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">19</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">36</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pallets/Container")} </p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">13680</p>
                </div>
              </div>
            </div>
            <div
              id="p5"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[70px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730749327/image_15_bpsfrn.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-2xl text-white p-2  text-center flex  items-center justify-center font-semibold h-[80px] gap-2">
                <div>
                  <p>1.6kg</p>
                  <p>(27 cm)</p>
                </div>
              </div>
              <div className="flex flex-col items-center text-[#717171] font-semibold">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">12</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">36</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pallets/Container")} </p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">8640</p>
                </div>
              </div>
            </div>
            <div
              id="p6"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[70px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730749327/image_15_bpsfrn.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-2xl text-white p-2  text-center flex  items-center justify-center font-semibold h-[80px] gap-2">
                <div>
                  <p>2kg</p>
                  <p>(30 cm)</p>
                </div>
              </div>
              <div className="flex flex-col items-center text-[#717171] font-semibold">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">8</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">42</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pallets/Container")} </p>
                  <p className="text-black font-bold">21</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">7056</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardDisc;
