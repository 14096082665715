import React, { useState, useEffect } from "react";
import Logo1 from "../assets/logoLight.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown";

const Navbar = ({ homeRef, contactUsRef }) => {
  const [openNav, setOpenNav] = useState(false);
  const [CurrentLogo, setCurrentLogo] = useState(Logo1);
  const [bgColor, setBgColor] = useState("bg-transparent");
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const { t } = useTranslation("common");
  const handleNavigation = (url) => {
    navigate(url);
    setOpenNav(false);
  };

  const scrollToTarget = (ref, component) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollTo: component } });
    }
    setOpenNav(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (openNav) {
      setBgColor("bg-[#54A341]");
    }
  }, [openNav]);

  const handleScroll = () => {
    if (window.scrollY > 600) {
      setCurrentLogo(Logo1);
      setBgColor("bg-[#54A341]");
    } else {
      setCurrentLogo(Logo1);
      setBgColor("bg-transparent");
    }
  };

  return (
    <nav
      className={`fixed top-0 z-50 ${bgColor} dark:border-gray-700 ${
        !openNav ? "h-[70px]" : ""
      } w-full`}
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between  h-full">
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 mt-2 justify-center text-md rounded-lg md:hidden hover:bg-gray-100 text-white hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          onClick={() => setOpenNav(!openNav)}
          aria-controls="navbar-dropdown"
          aria-expanded={openNav}
        >
          <i className="fa-solid fa-bars"></i>
        </button>

        <div
          className={`w-full md:block md:w-auto ${
            openNav ? "" : "hidden"
          } cursor-pointer`}
          id="navbar-dropdown"
        >
          <ul
            className={`flex flex-col font-medium cursor-pointer p-4 pl-8 lg:space-x-8 md:space-x-4 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 text-xl dark:border-gray-700`}
          >
            <li>
              <p
                className={`block py-2 px-3 rounded md:p-0 text-white`}
                aria-current="page"
                onClick={() => scrollToTarget(homeRef, "home")}
              >
                {t("home")}
              </p>
            </li>
            <li>
              <p
                className={`block py-2 px-3 rounded md:p-0 text-white`}
                onClick={() => handleNavigation("/products")}
              >
                {t("products")}
              </p>
            </li>
            <li>
              <p
                className={`block py-2 px-3 rounded md:p-0 text-white`}
                onClick={() => handleNavigation("/about-us")}
              >
                {t("aboutus")}
              </p>
            </li>
            <li>
              <p
                className={`block py-2 px-3 rounded md:p-0 text-white`}
                onClick={() => scrollToTarget(contactUsRef, "contactus")}
              >
                {t("contactus")}
              </p>
            </li>
          </ul>
        </div>
        <div className="lg:left-[46%] md:left-[45%] left-[35%] top-3 fixed">
          <div className="flex">
            <img
              src={CurrentLogo}
              className="h-[50px]"
              alt="Virglow Ventures"
            />
          </div>
        </div>
        <div className="z-50 absolute right-2">
          {!openNav && <LanguageDropdown />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
