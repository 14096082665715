import React, { useState } from "react";
import { motion } from "framer-motion"; // Importing Framer Motion for animations
import footerLogo from ".././assets/footer.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [email, setemail] = useState("");
  const emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isButtonEnabled = !emailRegx.test(email);
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  async function handleSubmit(e) {
    const d = new FormData();

    d.append("email", email);

    fetch(
      "https://script.google.com/macros/s/AKfycbzbSWIoXMjZmwqnWy2wiZsj7jrLuZnorwfKQtcu3gBLUBJMTHc6YTK4gytl9dLKaTT9Qw/exec",
      {
        method: "POST",
        body: d,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setemail("");
      })
      .catch((error) => {
        setemail("");
      });
    setemail("");
    notify();
  }

  const scrollToTarget = (component) => {
    if (component == "aboutus") {
      navigate("/about-us");
    } else if (component == "products") {
      navigate("/products");
    } else {
      navigate("/", { state: { scrollTo: component } });
    }
  };

  const notify = () => toast.success("Your Email has been Saved Successfully");

  return (
    <div className="bg-[#28601A]">
      <ToastContainer />
      <motion.div
        initial={{ opacity: 0, y: 30 }} // Footer initial animation
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="flex flex-col md:flex-row gap-10 justify-between p-4 md:pl-10 md:pr-10 "
      >
        {/* Footer Logo Section */}
        <motion.div
          whileHover={{ scale: 1.1 }} // Hover effect on logo
          className="flex justify-center md:justify-start"
        >
          <img
            src={footerLogo}
            alt="footer logo"
            className="w-[200px] h-[250px] md:w-[300px] md:h-[350px] mt-10"
          />
        </motion.div>

        {/* Navigation and Address Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="text-lg md:text-xl flex flex-col gap-4 items-center md:items-start justify-center"
        >
          {/* Navigation Links */}
          <div className="font-medium text-[#54A341] text-center md:text-left cursor-pointer ">
            {["home", "products", "aboutus", "contactus"].map((item, index) => (
              <motion.p
                whileHover={{ scale: 1.1 }}
                key={index}
                onClick={() => scrollToTarget(item.toLowerCase())}
                className="p-1 hover:text-white"
              >
                {t(item)}
              </motion.p>
            ))}
          </div>

          {/* Address */}
          <div className="text-white text-center md:text-left">
            <motion.p className="text-3xl mt-2" whileHover={{ scale: 1.05 }}>
              {t("office")}
            </motion.p>
            <p className="leading-6 mt-2 text-sm">
              {t("addressLine1")} <br /> {t("addressLine2")}
              <br /> {t("addressLine3")}
              <br /> {t("addressLine4")}
              <p className="text-xl mt-2 " whileHover={{ scale: 1.05 }}>
                <i className="bx bx-envelope mr-2"></i>
                info@virglowventures.in
                <br />
                <i class="bx bx-phone"></i> +91 95970 31704
                <br />
                {/* <i class="bx bx-phone"></i> +91 80563 55360 */}
              </p>
            </p>
          </div>
        </motion.div>

        {/* Newsletter and Social Media Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
          className="flex flex-col items-center md:items-start gap-8 p-4"
        >
          {/* Newsletter */}
          <motion.p
            className="text-white text-lg md:text-xl"
            whileHover={{ scale: 1.05 }}
          >
            {t("subscribeNewsletter")}
          </motion.p>
          <div className="flex">
            <motion.input
              type="text"
              placeholder="Enter Your Email"
              className="p-1 w-full md:w-auto focus:outline-none focus:border-[#28601A] focus:ring-2 focus:ring-[#28601A] transition-all"
              onChange={(e) => setemail(e.target.value)}
            />
            <motion.button
              disabled={isButtonEnabled}
              onClick={handleSubmit}
              whileHover={{ scale: 1.1 }}
              className={`${
                !isButtonEnabled
                  ? " bg-[#54A341] text-white p-1 pl-2 pr-2"
                  : "bg-gray-400 text-white p-1 pl-2 pr-2"
              }`}
            >
              <i className="fa-solid fa-chevron-right" />
            </motion.button>
          </div>

          {/* Social Media Icons */}
          <div className="text-2xl flex gap-4 text-[#54A341] cursor-pointer">
            {["fa-instagram", "fa-linkedin"].map((icon, index) => (
              <motion.i
                whileHover={{ scale: 1.2 }}
                key={index}
                className={`fa-brands ${icon} text-white ${
                  index % 2 === 1 ? "text-white" : ""
                }`}
              />
            ))}
          </div>

          {/* Privacy and Terms Links */}
          <motion.div className="text-lg md:text-xl font-medium cursor-pointer">
            <motion.p
              whileHover={{ scale: 1.05 }}
              className="text-[#54A341] hover:text-white"
            >
              {t("privacyAndPolicy")}
            </motion.p>
            <motion.p
              whileHover={{ scale: 1.05 }}
              className="text-[#54A341] hover:text-white"
            >
              {t("termsAndCondition")}
            </motion.p>
          </motion.div>
        </motion.div>
      </motion.div>

      {/* Footer End: Disclaimer and Logo */}
      <div className="flex flex-col md:flex-row  relative mt-8 pb-4 px-4 md:px-10 md:gap-10 sm:gap-10">
        {/* Footer Logo */}
        <motion.div
          whileHover={{ transition: { duration: 1.5 } }}
          className="md:w-auto bottom-2 right-[3%] ml:absolute md:absolute self-center mb-4"
        >
          <img
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731389182/image_25_enuzsh.png"
            alt=""
            width="80px"
            className="lg:right-[80%] md:right-[80%] right-[65%] bottom-12 lg:bottom-1 absolute"
          />
          <img
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731398230/Frame_18_4_cehg1l.svg"
            alt="Logo"
            width="140px"
            className="ml-20"
          />
        </motion.div>
        {/* Disclaimer */}
        <p className="text-white text-sm text-center md:text-left bottom-5 right-[40%] ml:absolute md:absolute">
          © 2024 Virglow Ventures. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
