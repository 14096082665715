import React from "react";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Footer from "./Footer";
import bg from ".././assets/bg/somethingbg.svg";
import m1 from ".././assets/story/1.png";
import m2 from ".././assets/story/2.png";
import m3 from ".././assets/story/3.png";
import m4 from ".././assets/story/4.png";
import m5 from ".././assets/story/5.png";
import m6 from ".././assets/story/6.png";
import m7 from ".././assets/story/7.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import a1 from "../assets/about-us/Frame 92.svg";
import a2 from "../assets/about-us/Frame 93.svg";
import a3 from "../assets/about-us/Frame 94.svg";

const AboutUsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("aboutus");
  const handleNavigation = () => {
    // Push a new path to the history stack
    navigate("/");
  };
  const data = [
    {
      header: t("aboutc1h"),
      main: t("aboutc1p"),
    },
    {
      header: t("aboutc2h"),
      main: t("aboutc2p"),
    },
    {
      header: t("aboutc3h"),
      main: t("aboutc3p"),
    },
    {
      header: t("aboutc4h"),
      main: t("aboutc4p"),
    },
  ];

  return (
    <div>
      <Navbar />
      <div></div>
      <div className="overflow-hidden">
        {/* Header Image with fade-in on scroll */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="relative overflow-hidden max-h-[700px]"
        >
          <img
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1729173728/site/vqncudhfegyeme7l4qh6.svg"
            alt="Background"
            width="100%"
            className="filter brightness-50"
          />
          <motion.p
            className="absolute inset-0 flex items-center p-10 justify-center 
            lg:text-[80px] md:text-[50px] sm:text-[60px]
          lg:top-[5%]
          md:top-[5%]
          sm:top-[-15%]
          top-[5%]
        
          text-[30px] 
            text-white font-sans font-extralight"
          >
            <span className="font-extralight font-sans  mb-20 text-[#bababa]">
              {" "}
              {t("something")}
            </span>
            <br />
            {t("about")}
          </motion.p>
        </motion.div>

        {/* Breadcrumb with slide-in from left */}
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          whileHover={{ scale: 1.1 }}
          onClick={handleNavigation}
          className="flex justify-center gap-2 mt-10 items-center flex-wrap cursor-pointer"
        >
          <i className="fa-solid fa-chevron-left" />
          <span> {t("home")} /</span>
          <span className="text-3xl text-[#54A341] font-semibold">
            {t("about")}
          </span>
        </motion.div>

        {/* "Who are we?" section with zoom-in on scroll */}
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          className="w-[90%] m-auto"
        >
          <div className="flex justify-evenly mt-32 flex-wrap relative">
            <div className="w-[20%] top-0 left-4 h-[100%]">
              <img src={m2} alt="" className="absolute top-[-50px] left-10" />
            </div>
            <div className="lg:text-[90px] md:text-[70px] text-[50px]  font-thin text-center h-[200px] mt-28 text-[#28601A]  lg:w-[60%] md:w-[80%] w-[100%] mb-10 ">
              {t("abouth1")}
            </div>
            <div className="lg:w-[20%] md:w-[20%] w-[40%] relative">
              <img src={m7} alt="" className="absolute bottom-[0px] " />
            </div>
          </div>

          {/* Text content with fade-in */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.2 }}
            className="mt-10 p-2 leading-7 text-center mb-10"
          >
            {t("aboutb1")}
          </motion.div>
        </motion.div>

        {/* Image grid with staggered fade-up */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delayChildren: 0.2,
                staggerChildren: 0.2,
              },
            },
          }}
          className="flex flex-wrap relative h-[400px]"
        >
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            src={m3}
            alt=""
            className="absolute top-[-10px] left-20"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            src={m4}
            alt=""
            className="absolute top-[120px] left-[210px]"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            src={m5}
            alt=""
            className="absolute bottom-0 right-20 lg:top-10 md:top-10 sm:top-5 top-[350px] "
          />
        </motion.div>

        {/* Quote section with slide-in from right */}
        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="text-center w-[100%] text-[50px] text-[#54A341] mt-32 mb-[200px] font-robot font-md"
        >
          " {t("abouth2")}"
        </motion.div>

        {/* "What makes us different?" section with zoom-in on scroll */}
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          className="flex justify-evenly mt-20 flex-wrap mb-20 "
        >
          <div className="lg:w-[40%] md:w-[30%] w-[50%]  ">
            <img src={m6} alt="" className=" lg:ml-56 mb-4 ml-20 " />
            <img src={m1} alt="" className="mb-10" />
          </div>
          <div className="bg-[#28601A] lg:w-[60%] md:w-[60%] w-[100%] flex items-center justify-center text-white font-sans font-thin lg:text-[50px] text-[30px]  text-left ">
            {t("abouth3")} <br />
            {t("abouth33")}
          </div>
        </motion.div>
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className=" flex justify-end italic items-end text-right font-semibold"
        >
          <p className="lg:w-[50%] md:w-[70%] w-[90%] mr-2">{t("aboutb2")}</p>
        </motion.div>
        <motion.div className="flex flex-wrap justify-center mt-10">
          <img
            src={a1}
            alt=""
            className=" lg:w-[33%] md:w-[33%] sm:flex-grow"
          />
          <img
            src={a2}
            alt=""
            className=" lg:w-[33%] md:w-[33%] sm:flex-grow"
          />
          <img
            src={a3}
            alt=""
            className=" lg:w-[33%] md:w-[33%] sm:flex-grow"
          />
        </motion.div>

        <motion.div
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="bg-[#28601A] text-white font-sans font-thin lg:text-[50px] md:text-[40px] text-[35px] lg:w-[60%] md:w-[60%] w-[90%] h-[200px] text-center flex items-center justify-center mt-20"
        >
          {t("abouth4")}
        </motion.div>

        {/* Sustainability points with staggered fade-in */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delayChildren: 0.2,
                staggerChildren: 0.3,
              },
            },
          }}
          className="mt-32 w-[80%] m-auto flex flex-col gap-10 mb-28"
        >
          {data.map((d, index) => (
            <motion.div
              key={index}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
              }}
              className="leading-6"
            >
              <p className="text-[#54A341] text-2xl">{d.header}</p>
              <p className="mt-2">{d.main}</p>
            </motion.div>
          ))}
        </motion.div>

        <Footer />
      </div>
    </div>
  );
};

export default AboutUsPage;
