import React from "react";
import { useTranslation } from "react-i18next";

const ProductCardSlab = () => {
  const { t } = useTranslation("products");

  return (
    <div className="bg-[#F4F3F1]  flex flex-col gap-4 p-4 w-[100%]  rounded-md">
      <div className="bg-[#DEFFD6] rounded-sm">
        <p className="text-[#28601A] text-3xl font-bold text-center p-2">
          {t("cocoGrowBags")}
        </p>
      </div>
      <div className="flex gap-10 lg:flex-nowrap lg:justify-normal justify-center flex-wrap ">
        <div>
          <img
            className="rounded-md max-h-[254px] max-w-[256px]"
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731393607/Frame_116_t1aauf.png"
            alt=""
          />
          <div className="bg-[#28601A] p-2 border-r-2 text-white mt-4 text-center rounded-md text-lg font-semibold">
            {t("slabs")}
          </div>
        </div>
        <div>
          <p className="text-[#54A341] text-xl mb-4  font-semibold">
            {t("available")}
          </p>
          <div className="flex gap-4  flex-wrap items-center lg:justify-normal justify-center">
            <div
              id="p1"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394213/Group_17_wjdfhz.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>2kg</p>
                <p>(100 x 15 x 15)</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">504</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">10080</p>
                </div>
              </div>
            </div>
            <div
              id="p2"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394230/Group_16_1_wtblja.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>2.5kg</p>
                <p>(100 x 18 x 18)</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">450</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">9000</p>
                </div>
              </div>
            </div>
            <div
              id="p3"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394213/Group_17_wjdfhz.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>3kg</p>
                <p>(100 x 20 x 20)</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">400</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">8000</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-[100%] flex-wrap">
              <p className="text-[#54A341] font-semibold text-xl mb-4">
                {t("available")}
              </p>
              <div className="flex gap-10 items-center justify-start flex-wrap">
                <div className="flex flex-col items-center ">
                  <img
                    className="w-[350px] h-[80px]"
                    src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394396/image_21_1_o8qzbv.png"
                    alt=""
                  />

                  <div className="flex flex-col justify-center content-center items-center">
                    <p className="font-semibold">
                      {t("uv")} {t("bag")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center ">
                  <img
                    className="w-[350px] h-[80px]"
                    src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394397/image_22_2_hbbow0.png"
                    alt=""
                  />
                  <div className="flex flex-col justify-center content-center items-center">
                    <p className="font-semibold">{t("meshCocoSlab")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSlab;
