import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Products from "./Components/Products";
import AboutUsPage from "./Components/AboutUsPage";
import ScrollToTop from "./Components/ScrollToTop";
import KnowMore from "./Components/KnowMore";
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n";
import Loader from "./Loader";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop /> {/* Include ScrollToTop here */}
        <App />
      </>
    ),
  },

  {
    path: "/products",
    element: (
      <>
        <ScrollToTop /> {/* Include ScrollToTop here */}
        <Products />
      </>
    ),
  },
  {
    path: "/about-us",
    element: (
      <>
        <ScrollToTop /> {/* Include ScrollToTop here */}
        <AboutUsPage />
      </>
    ),
  },
  {
    path: "/know-more",
    element: (
      <>
        <KnowMore />
      </>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <React.Suspense fallback={Loader}>
      <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </React.Suspense>
  </React.StrictMode>
);
