import React from "react";
import * as motion from "framer-motion/client";
import leaf from ".././assets/leaf-green.svg";
import { useTranslation } from "react-i18next";

const AboutUs = ({ AboutRef }) => {
  const { t } = useTranslation("home");

  return (
    <motion.div
      ref={AboutRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 3 }}
      className="flex content-center justify-between  items-center flex-wrap overflow-hidden"
    >
      <motion.div
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 1.1 }}
        initial={{ x: "-80%", opacity: 0 }}
        animate={{ x: "0%", scale: 1.2 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="  font-semibold text-3xl mt-10 mb-10 text-white flex 
         lg:w-[45.5%] md:w-[45.5%] sm:w-[100%] w-[100%] ;
        "
      >
        <div className="h-[650px] w-[100%] flex items-center justify-center bgimageg lg:text-[55px] md:text-[40px] text-4xl text-center font-medium leading-[70px]  ">
          <span>
            {t("growBetterh1")} <br />
            {t("growBetterh2")}
            <br />
            {t("growBetterh3")}
          </span>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "-10%", opacity: 0 }}
        animate={{ y: "0%", scale: 1.1 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        className="lg:w-[50%] md:w-[50%] [100%] w-[100%] mt-10 mb-10  lg:p-20 md:p-20 p-10  leading-8"
      >
        <img src={leaf} alt="" />
        <span className="text-[#28601A]">{t("growBetterBody")}</span>
      </motion.div>
    </motion.div>
  );
};

export default AboutUs;
