import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageDropdown = () => {
  // Retrieve the saved language from localStorage or fall back to default
  const initialLanguage = JSON.parse(
    localStorage.getItem("selectedLanguage")
  ) || {
    code: "en",
    label: "EN",
    flag: "https://flagpedia.net/data/flags/h80/us.webp",
  };

  const selectedLanguage = useRef(initialLanguage);
  const bgColor = useRef("bg-[#801515]");
  const border = useRef("border-[0px]");

  const { i18n } = useTranslation();

  const handleScroll = () => {
    if (window.scrollY > 600) {
      bgColor.current = "bg-[#54A341]";
      border.current = "border-[1px]";
    } else {
      bgColor.current = "bg-[#801515]";
      border.current = "border-[0px]";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    {
      code: "en",
      label: "EN",
      flag: "https://flagpedia.net/data/flags/h80/us.webp",
    },
    {
      code: "de",
      label: "DE",
      flag: "https://flagpedia.net/data/flags/h80/de.webp",
    },
    {
      code: "es",
      label: "ES",
      flag: "https://flagpedia.net/data/flags/h80/es.webp",
    },
    {
      code: "nl",
      label: "NL",
      flag: "https://flagpedia.net/data/flags/h80/nl.webp",
    },
    {
      code: "fr",
      label: "FR",
      flag: "https://flagpedia.net/data/flags/h80/fr.webp",
    },
    {
      code: "zh-CN",
      label: "ZH",
      flag: "https://flagpedia.net/data/flags/h80/cn.webp",
    },
    {
      code: "it",
      label: "IT",
      flag: "https://flagpedia.net/data/flags/h80/it.webp",
    },
    {
      code: "ru",
      label: "RU",
      flag: " https://flagpedia.net/data/flags/h80/ru.webp",
    },
  ];

  const handleLanguageSelect = (language) => {
    selectedLanguage.current = language;
    i18n.changeLanguage(language.code);
    localStorage.setItem("selectedLanguage", JSON.stringify(language)); // Save selected language to localStorage
    setIsOpen(false);
  };

  const FlagComp = ({ img, name }) => (
    <div className="flex items-center">
      <img src={img} alt={name} className="w-5 h-5 rounded-full mr-2" />
      {name}
    </div>
  );

  return (
    <div className="relative inline-block text-left z-[1000] right-2 w-[80px]">
      {/* Dropdown Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`p-1 rounded-md ${bgColor.current} text-white flex items-center focus:outline-none ${border.current}`}
      >
        <img
          src={selectedLanguage.current.flag}
          alt={selectedLanguage.current.label}
          className="w-5 h-5 rounded-full mr-2"
        />
        {selectedLanguage.current.label}
        <span className="ml-2">▾</span>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul className="absolute left-0 mt-2 w-[80px] w-32 bg-[#012B11] rounded-md shadow-lg border border-gray-500">
          {languages.map((language) => (
            <li
              key={language.code}
              onClick={() => handleLanguageSelect(language)}
              className="px-4 py-2 text-white flex items-center hover:bg-[#013919] cursor-pointer"
            >
              <FlagComp img={language.flag} name={language.label} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageDropdown;
