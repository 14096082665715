import React, { useState } from "react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaUser,
  FaEnvelope,
  FaBuilding,
  FaGlobe,
  FaComment,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ContactUs = ({ contactUsRef }) => {
  const { t } = useTranslation(["home", "common"]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    company: "",
    message: "",
  });

  const nameRegex = /^[A-Za-z\s'-]+$/; // Only letters, spaces, apostrophes, and hyphens
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

  const isSubmitEnabled =
    !nameRegex.test(formData.name) || !emailRegex.test(formData.email);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const notify = () => {
    toast.success(t("notification", { ns: "common" }), {
      autoClose: 2000,
      delay: 1000,
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    const d = new FormData();
    d.append("name", formData.name);
    d.append("email", formData.email);
    d.append("phone", formData.phone);
    d.append("country", formData.country);
    d.append("company", formData.company);
    d.append("message", formData.message);
    //notify();

    fetch(
      "https://script.google.com/macros/s/AKfycbwp3JcJk6HAO-bdzVsREyWFyamwCOolmNClhkck_Uwl37vuL15KWKmoPezJQtktnV0B/exec",
      {
        method: "POST",
        body: d,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          country: "",
          company: "",
          message: "",
        });
      })
      .catch((error) => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          country: "",
          company: "",
          message: "",
        });
      });
  }

  return (
    <div className="flex items-center justify-center gap-10 mt-10 lg:p-14 w-[90%] mx-auto flex-wrap">
      <div className="flex flex-col">
        <p className="text-[#28601A] text-6xl text-center font-sans font-semibold">
          {t("contactUsh1")}
        </p>
        <p className="text-center mt-4 w-[80%] m-auto inline-block text-lg">
          {t("contactUsh2")}
        </p>
      </div>

      {/* Heading Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="text-[#28601A] text-center"
      >
        <motion.span
          className="text-3xl sm:text-4xl font-light mt-10"
          whileHover={{ scale: 1.1 }}
        >
          {t("let")}
        </motion.span>{" "}
        <motion.span
          className="text-4xl sm:text-5xl font-semibold"
          whileHover={{ scale: 1.1 }}
        >
          {t("talk")}
        </motion.span>
      </motion.div>

      {/* Form Section */}
      <div className="mx-auto p-8 bg-white lg:w-[60%] rounded-lg">
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-2 gap-8"
          ref={contactUsRef}
        >
          {/* Name */}
          <div className="flex items-center border-b border-gray-300 w-full focus-within:border-[#28601A] focus-within:border-b-2 focus-animation">
            <FaUser className="text-gray-400 mr-2" />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder={t("name")}
              className="w-full p-2 text-gray-700 outline-none"
            />
          </div>

          {/* Mobile Number */}
          <div className="flex items-center border-b border-gray-300 w-full focus-within:border-[#28601A] focus-within:border-b-2 focus-animation">
            <i className="fa-solid fa-phone text-gray-400"></i>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder={t("number")}
              className="w-full p-2 text-gray-700 outline-none"
            />
          </div>

          {/* Email ID */}
          <div className="flex items-center border-b border-gray-300 w-full focus-within:border-[#28601A] focus-within:border-b-2 focus-animation">
            <FaEnvelope className="text-gray-400 mr-2" />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t("email")}
              className="w-full p-2 text-gray-700 outline-none"
            />
          </div>

          {/* Country */}
          <div className="flex items-center border-b border-gray-300 w-full focus-within:border-[#28601A] focus-within:border-b-2 focus-animation">
            <FaGlobe className="text-gray-400 mr-2" />
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleChange}
              placeholder={t("country")}
              className="w-full p-2 text-gray-700 outline-none"
            />
          </div>

          {/* Company Name */}
          <div className="col-span-2 flex items-center border-b border-gray-300 w-full focus-within:border-[#28601A] focus-within:border-b-2 focus-animation">
            <FaBuilding className="text-gray-400 mr-2" />
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              placeholder={t("company")}
              className="w-full p-2 text-gray-700 outline-none"
            />
          </div>

          {/* Message */}
          <div className="col-span-2 flex items-start border border-gray-300 border-t-0 border-l-0 border-r-0  w-full p-2 focus-within:border-[#28601A] focus-within:border-b-2 focus-animation">
            <FaComment className="text-gray-400 mr-2 mt-3" />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t("message")}
              rows="4"
              className="w-full p-2 text-gray-700 outline-none resize-none"
            />
          </div>

          {/* Submit Button */}
          <div className="col-span-2 flex items-center">
            <button
              type="submit"
              // disabled={isSubmitEnabled}
              className={`px-4 py-2 rounded-md ${
                isSubmitEnabled
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-700 text-white hover:bg-green-800"
              }`}
            >
              {t("submit")}
            </button>
            <p className="text-gray-500 text-sm ml-4">{t("info")}</p>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ContactUs;
