import React from "react";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductCardCoir from "./ProductCardCoir";
import ProductCardDisc from "./ProductCardDisc";
import ProductCardCube from "./ProductCardCube";
import ProductCardSlab from "./ProductCardSlab";
import ProductCardCoins from "./ProductCardCoins";
import ProductCardHusk from "./ProductCardHusk";

const Products = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("products");

  const handleNavigation = () => {
    // Push a new path to the history stack
    navigate("/");
  };

  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <Navbar />
        <div>
          {/* Background image with fade-in animation */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="relative overflow-hidden max-h-[700px]"
          >
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1729173499/site/ourstory.svg"
              alt="Background"
              width="100%"
              className="filter brightness-50 "
            />
            <motion.p
              className="absolute inset-0 flex items-center p-10 justify-center 
            lg:text-[80px] md:text-[70px] sm:text-[60px]
          lg:top-[5%]
          md:top-[5%]
          sm:top-[-15%]
          top-[5%]
          text-white
        
          text-[35px] 
           font-sans font-extralight"
            >
              <span className="font-extralight font-sans  mr-6 text-[#bababa]">
                {t("our")}{" "}
              </span>{" "}
              {t("products")}
            </motion.p>
          </motion.div>
          {/* Breadcrumb navigation with chevron animation */}
          <motion.div
            className="flex justify-center gap-2 mt-10  items-center cursor-pointer"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            whileHover={{ scale: 1.1 }}
            onClick={handleNavigation}
          >
            <motion.i
              className="fa-solid fa-chevron-left"
              whileHover={{ x: -5 }}
            />
            <span>{t("home")} / </span>
            <motion.span
              className="text-2xl text-[#54A341] font-semibold"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              {t("ourProducts")}
            </motion.span>
          </motion.div>
          <div className="flex flex-col flex-wrap gap-10  justify-center p-4 items-center mt-10">
            <ProductCardCube />
            <ProductCardDisc />
            <ProductCardSlab />
            <ProductCardCoir />

            <ProductCardCoins />
            <ProductCardHusk />
          </div>
        </div>
      </motion.div>
      <div>
        <p className="text-center text-[#28601A] text-3xl font-semibold">
          {t("packaging")}
        </p>
        <div className="p-4 flex gap-6 flex-wrap justify-evenly">
          <div className=" bg-[#F7F5F6] relative border-black border-[1px] flex flex-col md:w-[25%] lg:w-[30%] w-[80%] p-2 items-center justify-center content-center gap-4 rounded-lg">
            <div className="text-center">
              <p className="font-bold text-2xl lg:mt-0 md:mt-0 mt-10">
                {t("uv")}
              </p>
              <p>{t("openTop")}</p>
            </div>
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731396946/image_18_vgybd6.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731397185/Frame_122_2_ijowxm.png"
              className="absolute left-0 top-0"
              alt=""
            />
            <p className="text-sm w-[80%] mt-2 mb-2 text-center font-medium italic">
              {t("packingp1")}
            </p>
          </div>
          <div className="bg-[#F7F5F6] relative border-black border-[1px] flex flex-col lg:w-[30%] md:w-[25%] w-[80%] p-2 items-center justify-center gap-2 rounded-lg">
            <div className="text-center">
              <p className="font-bold text-2xl">{t("fabric")}</p>
              <p>{t("openTop")}</p>
            </div>
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731396946/image_19_1_xttctc.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731397185/Frame_122_mmc3w9.png"
              className="absolute left-0 top-0"
              alt=""
            />
            <p className="text-sm w-[80%] mt-2 mb-2 text-center font-medium italic">
              {t("packingp2")}
            </p>
          </div>

          <div className="bg-[#F7F5F6] relative border-black border-[1px] flex flex-col lg:w-[30%] md:w-[25%] w-[80%] p-2 items-center justify-center gap-2 rounded-lg">
            <div className="text-center">
              <p className="font-bold text-2xl">{t("mesh")}</p>
              <p>{t("openTop")}</p>
            </div>
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731396946/image_16_1_xrnien.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731397184/Frame_122_1_nn3qmn.png"
              className="absolute left-0 top-0"
              alt=""
            />
            <p className="text-sm w-[80%] mt-2 mb-10 text-center font-medium italic">
              {t("packingp3")}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col mt-10">
          <p className="self-center text-[#28601A] text-3xl font-semibold">
            {t("specifications")}
          </p>
          <p className="self-center text-xl">{t("common")}</p>
          <div className="bg-[#F4F3F1] mt-10 grid grid-cols-[1fr_auto_1fr] gap-y-4 p-6 rounded-lg lg:w-[45%] md:w-[60%] w-[95%]  self-center">
            <p className="text-gray-600">{t("s1A")}</p>
            <div className="flex justify-center items-center text-gray-600 font-bold">
              :
            </div>
            <p className="font-semibold">{t("s1A1")}</p>

            <p className="text-gray-600">{t("s1B")}</p>
            <div className="flex justify-center items-center  w-[100px] text-gray-600 font-bold">
              :
            </div>
            <p className="font-semibold">{t("s1B1")}</p>

            <p className="text-gray-600">{t("s1C")}</p>
            <div className="flex justify-center items-center text-gray-600 font-bold">
              :
            </div>
            <p className="font-semibold">{t("s1C1")}</p>

            <p className="text-gray-600">{t("s1D")}</p>
            <div className="flex justify-center items-center text-gray-600 font-bold">
              :
            </div>
            <p className="font-semibold">{t("s1D1")}</p>

            <p className="text-gray-600">{t("s1E")}</p>
            <div className="flex justify-center items-center text-gray-600 font-bold">
              :
            </div>
            <p className="font-semibold">{t("s1E1")}</p>

            <p className="text-gray-600">{t("s1F")}</p>
            <div className="flex justify-center items-center text-gray-600 font-bold">
              :
            </div>
            <p className="font-semibold">{t("s1F1")}</p>

            <p className="text-gray-600">{t("s1G")}</p>
            <div className="flex justify-center items-center text-gray-600 font-bold">
              :
            </div>
            <p className="font-semibold">{t("s1G1")}</p>
          </div>
        </div>
      </div>

      {/* Footer section */}
      <motion.div
        className="mt-[100px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Footer />
      </motion.div>
    </>
  );
};

export default Products;
