import React from "react";
import { motion } from "framer-motion";
import leaf from "../assets/leaf-white.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeProducts = ({ ProductRef }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const handleNavigation = () => {
    // Push a new path to the history stack
    navigate("/products");
  };

  const data = [
    {
      id: 1,
      name: t("homeP1"),
      image:
        "https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731389789/image_29_ojosth.svg",
    },
    {
      id: 2,
      name: t("homeP2"),
      image:
        "https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731389791/image_28_wbnl8o.svg",
    },
    {
      id: 3,
      name: t("homeP3"),
      image:
        "https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731389830/Frame_116_hnchg8.svg",
    },
    {
      id: 4,
      name: t("homeP4"),
      image:
        "https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731389873/image_26_yqzzin.png",
    },
    {
      id: 5,
      name: t("homeP5"),
      image:
        "https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731390063/image_23_l4me88.jpg",
    },
  ];

  return (
    <motion.div
      ref={ProductRef}
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1.5, ease: "easeOut" }}
      className="flex flex-col items-center justify-around p-10 gap-4"
    >
      {/* Title Section */}
      <motion.p className="mt-5  leading-10">
        <motion.span
          whileHover={{ scale: 1.2, rotate: 10 }}
          whileTap={{ scale: 1.1, rotate: -10 }}
          initial={{ x: "100%", opacity: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5 }}
          className="font-sans text-[#E2D905] font-light text-5xl"
        >
          {t("homeProdh1")}
        </motion.span>
        <br />
        <motion.span
          whileHover={{ scale: 1.2, rotate: -10 }}
          whileTap={{ scale: 1.1, rotate: 10 }}
          initial={{ x: "80%", opacity: 0 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, delay: 0.3 }}
          className="text-[#28601A] font-medium text-5xl mt-4"
        >
          {t("homeProdh2")}
        </motion.span>
      </motion.p>

      {/* Product Cards */}
      <motion.div
        animate={{ opacity: 1 }}
        transition={{ duration: 2, staggerChildren: 0.3 }}
        className="flex flex-wrap gap-4 justify-around w-[99%] p-5 "
      >
        {data.map((d, index) => (
          <motion.div
            key={d.id}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1.05 }}
            initial={{ y: "40%", opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1.2, delay: index * 0.3 }}
            className="flex flex-col items-center justify-evenly w-[220px] lg:mt-0 md:mt-0 sm:mt-0 mt-6 bg-white "
          >
            {/* Product Image */}
            <motion.img
              src={d.image}
              alt={d.name}
              height="340px"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5 }}
              className="rounded-md"
            />
            {/* Product Name */}
            <motion.p
              initial={{ y: "-30%", opacity: 0 }}
              animate={{ y: "0%", opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="mt-4 text-[#28601A] font-bold text-xl"
            >
              {d.name}
            </motion.p>
          </motion.div>
        ))}
      </motion.div>

      {/* Discover More Button */}
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.05 }}
        transition={{ duration: 0.5 }}
      >
        <button
          className="flex bg-[#28601A] p-3 cursor-pointer text-white items-center gap-2"
          onClick={handleNavigation}
        >
          <motion.img
            src={leaf}
            alt=""
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 1 }}
          />
          {t("discoverMore")}
        </button>
      </motion.div>
    </motion.div>
  );
};

export default HomeProducts;
