import React from "react";
import { useTranslation } from "react-i18next";

const ProductCardCoir = () => {
  const { t } = useTranslation("products");

  return (
    <div className="bg-[#F4F3F1]  flex flex-col gap-4 p-4  rounded- w-[100%]">
      <div className="bg-[#DEFFD6] rounded-sm">
        <p className="text-[#28601A] text-3xl font-bold text-center p-2">
          {t("cocoPeatBlock")}
        </p>
      </div>
      <div className="flex gap-10 lg:flex-nowrap flex-wrap lg:justify-normal  justify-evenly">
        <div>
          <img
            className="rounded-md max-h-[254px] max-w-[216px]"
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731501786/5-cocopeat-blocks-5-kg-1-snd-enterprises-original-imagregvkhhcwzud.jpeg_pdfrqv.jpg"
            alt=""
          />
          <div className="bg-[#28601A] p-2 border-r-2 text-white mt-10 text-center rounded-sm text-lg font-semibold">
            {t("blocks")}
          </div>
        </div>
        <div>
          <p className="text-[#54A341] text-xl mb-4 font-semibold">
            {t("available")}
          </p>
          <div className="flex gap-4  flex-wrap lg:justify-normal  justify-evenly ">
            <div
              id="p1"
              className="rounded-lg  border-2 border-gray-200 w-[200px] h-[320px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[80px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731522031/image_37-removebg-preview_pbaqxs.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>5kg</p>
                <p>{t("chips")}</p>
              </div>
              <div className="flex flex-col items-center  font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">220</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Container")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">4400</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalWeight")}</p>
                  <p className="text-black font-bold">2200kg</p>
                </div>
              </div>
            </div>
            <div
              id="p5"
              className="rounded-lg  border-2 border-gray-200 w-[200px] h-[320px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[75px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394672/image_17_2_r1eczy.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>5kg</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">220</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Container")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">4400</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalWeight")}</p>
                  <p className="text-black font-bold">2200kg</p>
                </div>
              </div>
            </div>
            <div
              id="p2"
              className="rounded-lg  border-2 border-gray-200 w-[200px] h-[320px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[75px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394672/image_17_2_r1eczy.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>1kg</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">21</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallet")}</p>
                  <p className="text-black font-bold">40</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pallets/Container")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">16800</p>
                </div>
              </div>
            </div>
            <div
              id="p3"
              className="rounded-lg  border-2 border-gray-200 w-[200px] h-[320px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[50px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731501786/WhatsApp_Image_2024-11-13_at_17.55.07-removebg-preview_cpopbm.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>650g</p>
                <p>BRIQUETT</p>
              </div>
              <div className="flex flex-col items-center  font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">1800</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Container")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">36000</p>
                </div>
              </div>
            </div>
            <div
              id="p4"
              className="rounded-lg  border-2 border-gray-200 w-[200px] h-[320px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[75px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394672/image_17_2_r1eczy.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>400g - 450g</p>
                <p>(16 x 16 x 16)</p>
              </div>
              <div className="flex flex-col items-center text-[#717171] font-semibold ">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">2080</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Container")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">41600</p>
                </div>
              </div>
            </div>

            <div
              id="p6"
              className="rounded-lg  border-2 border-gray-200 w-[200px] h-[320px] h-[320px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[75px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731394672/image_17_2_r1eczy.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>380g - 430g</p>
                <p>(15 x 15 x 15)</p>
              </div>
              <div className="flex flex-col items-center text-[#717171] font-semibold ">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Pallet")}</p>
                  <p className="text-black font-bold">2240</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Pallets/Container")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">44800</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardCoir;
