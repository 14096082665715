import React from "react";
import { useTranslation } from "react-i18next";

const ProductCardCube = () => {
  const { t } = useTranslation("products");

  return (
    <div className="bg-[#F4F3F1]  flex flex-col gap-4 p-4 w-[100%] rounded-md">
      <div className="bg-[#DEFFD6] rounded-sm">
        <p className="text-[#28601A] text-3xl font-bold text-center p-2">
          {t("cocoGrowCubes")}
        </p>
      </div>
      <div className="flex gap-10 lg:flex-nowrap flex-wrap lg:justify-normal  justify-evenly">
        <div>
          <img
            className="rounded-md h-[254px] w-[300px]"
            src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731393992/Frame_116_2_bb78pz.svg"
            alt=""
          />
          <div className="bg-[#28601A] p-2 border-r-2 text-white mt-4 text-center rounded-md text-lg font-semibold">
            {t("cubes")}
          </div>
        </div>
        <div className="bg-[background:#F4F3F1]">
          <p className="text-[#54A341] text-xl mb-4 font-semibold">
            {t("available")}
          </p>
          <div className="flex gap-4  flex-wrap  lg:justify-normal  justify-center">
            <div
              id="p1"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[100px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731392634/image_16_cc9ixe.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-2xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>150g</p>
                <p>(10 x 10 x 10)</p>
              </div>
              <div className="flex flex-col items-center text-[#717171] font-semibold">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">150</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">40</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]  rounded-lg">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">120000</p>
                </div>
              </div>
            </div>
            <div
              id="p2"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[100px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731392634/image_16_cc9ixe.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-2xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>350g</p>
                <p>(15 x 15 x 10)</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">76</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">40</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">60800</p>
                </div>
              </div>
            </div>
            <div
              id="p3"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[100px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731392634/image_16_cc9ixe.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-2xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>400g</p>
                <p>(15 x 15 x 15)</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">56</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">40</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">44800</p>
                </div>
              </div>
            </div>
            <div
              id="p4"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[100px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731392634/image_16_cc9ixe.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-2xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>900g</p>
                <p>(20 x 20 x 20)</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">21</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">40</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">16800</p>
                </div>
              </div>
            </div>
            <div
              id="p5"
              className="rounded-lg  border-2 border-gray-200 w-[200px]"
            >
              <div className=" w-[100%] flex justify-center p-2">
                <img
                  className="w-[100px]"
                  src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731392634/image_16_cc9ixe.png"
                  alt=""
                />
              </div>
              <div className="bg-[#54A341] text-white p-2 text-2xl text-center flex flex-col items-center justify-center font-semibold h-[80px]">
                <p>1.1kg</p>
                <p>(20 x 20 x 25)</p>
              </div>
              <div className="flex flex-col items-center font-semibold text-[#717171]">
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px]">
                  <p>{t("Pcs/Box")}</p>
                  <p className="text-black font-bold">18</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("Boxes/Pallets")}</p>
                  <p className="text-black font-bold">40</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] ">
                  <p>{t("Pallets/Containers")}</p>
                  <p className="text-black font-bold">20</p>
                </div>
                <div className="flex justify-between p-2 items-center w-[100%] h-[40px] bg-[#D0D0D0] rounded-lg">
                  <p>{t("totalPcs")}</p>
                  <p className="text-black font-bold">14400</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[100%] mt-10">
              <p className="text-[#54A341]  font-semibold text-xl mb-4">
                {t("available")}
              </p>
              <div className="flex gap-10 items-center justify-start flex-wrap">
                <div className="flex flex-col items-center ">
                  <img
                    src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1731392634/image_16_cc9ixe.png"
                    alt=""
                  />
                  <div className="flex flex-col font-semibold justify-center content-center items-center">
                    <p className="font-semibold">{t("mesh")}</p>
                    <p>{t("openTop")}</p>
                  </div>
                </div>
                <div className="flex font-semibold flex-col items-center ">
                  <img
                    className="w-[100px]"
                    src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730750734/image_13_hdhbik.png"
                    alt=""
                  />
                  <div className="flex flex-col justify-center content-center items-center">
                    <p className="font-semibold">{t("uv")}</p>
                    <p>{t("openTop")}</p>
                  </div>
                </div>
                <div className="flex flex-col items-center ">
                  <img
                    className="w-[100px]"
                    src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730750733/image_14_zhlxwq.png"
                    alt=""
                  />
                  <div className="flex font-semibold flex-col justify-center content-center items-center">
                    {" "}
                    <p className="font-semibold">{t("fabric")}</p>
                    <p>{t("openTop")}</p>
                  </div>
                </div>
                <div className="flex flex-col items-center ">
                  <img
                    className="w-[100px]"
                    src="https://res.cloudinary.com/dlfqmwzzf/image/upload/v1730750754/image_15_1_algmmd.png"
                    alt=""
                  />
                  <div className="flex font-semibold flex-col justify-center content-center items-center">
                    <p className="font-semibold">
                      {t("mesh")} {t("openTop")} {t("in")}
                    </p>

                    <p>{t("uv")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardCube;
